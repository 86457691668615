<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">建模接种</h3>
    <div class="detail">
      <h4 class="title">建模接种</h4>
      <el-form ref="vaccinaReview" size="small" :model="modelVacc" class="addForm" label-suffix=":"
               label-width="100px">
        <el-tabs v-model="nowNumber" class="tabsCage" type="card" @tab-click="clickTab">
          <el-tab-pane
              :key="key"
              v-for="(item,key) in arr"
              :label="'LS '+item"
              :name="item"
          >
            <div class="desc">
              <span>笼架编号：{{ frameNo }}</span>
            </div>
            <el-table
                :data="vaccDetailListTab" border
                class="tableMember"
                :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                style="width: calc(100% - 12px);font-size:14px;color:#333">
              <el-table-column
                  prop="overbit" width="100"
                  label="耳号"
                  show-overflow-tooltip></el-table-column>
              <el-table-column
                  label="性别" width="60"
                  show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.gender == 0 ? '♀' : '♂' }}
                </template>
              </el-table-column>
              <el-table-column class-name="isClass"
                               label="接种方式">
                <template slot-scope="scope">
                  <el-checkbox-group v-model="scope.row.sites" class="checkBox"
                                     @change="((val)=>{getCheckedVacc(val,scope.row)})">
                    <el-checkbox :label="item.id" v-for="item in susList" :key="item.id">
                      <span>试剂种类：{{ item.cellType }}</span>
                      <span class="ml-2">接种方式：{{ item.vaccSite }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </template>
              </el-table-column>
              <el-table-column class-name="isClass"
                               label="是否接种成功">
                <template slot-scope="scope">
                  <el-radio-group v-model="scope.row.isFail" class="radioGroup">
                    <el-radio :label="0">是</el-radio>
                    <el-radio :label="1">否</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column class-name="isClass"
                               label="备注">
                <template slot-scope="scope">
                  <el-input class="remark"
                            size="small"
                            placeholder="请输入备注信息"
                            v-model="scope.row.remark"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <h4 class="title" style="margin-top:16px">指定复核人</h4>
        <el-form-item label="复核人员">
          <el-radio-group v-model="modelVacc.reviewName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="saveRecord(1)">配制完成</el-button>
          <el-button plain type="primary" size="small" @click="saveRecord(0)">保存</el-button>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: "vaccination",
  props: ['id'],
  data() {
    return {
      cageMap: {},
      //当前绑定的
      nowNumber: '',
      // 上一个绑定的
      prevNumber: null,
      // 接种部位
      susList: [],
      // 表格
      vaccDetailListTab: [],
      arr: [],
      // 提交数据
      modelVacc: {
        reviewer: null,
        reviewName: "",
        vaccDetailList: []
      },
      // state: null,
      userList: [],
      //存放
      saveList: [],
      // 是否第一次加载
      isFirst: true,

      firstIndex: null
    }
  },
  mounted() {
    this.getData()
    this.getSusList()
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
  },
  methods: {
    getData() {
      this.$get('/model/vacc/' + this.id).then(res => {
        if (res.status == 200) {
          // this.state = res.data.modelVacc.state
          // if (this.state == null) {
          this.cageMap = res.data.cageMap
          if (this.nowNumber == 0) {
            for (const key in res.data.cageMap) {
              this.arr.push(key)
            }
            this.getFirstData()
            this.nowNumber = this.arr[0]
            this.prevNumber = this.arr[0]
          }
          this.getDataWay()
          this.isFirst = false

        }
      })
    },
    getDataWay() {
      if (this.cageMap[this.nowNumber].length > 0) {
        let newArr = []
        this.frameNo = this.cageMap[this.nowNumber][0].frameNo
        this.cageMap[this.nowNumber].forEach(item => {
          let newObj = {
            cageNo: item.cageNo,
            gender: item.gender,
            isFail: 0,
            overbit: item.overbit,
            partAniId: item.id,
            remark: "",
            susDetailIds: "",
            sites: [],
            subId: this.id
          }
          newArr.push(newObj)
        })
        if (this.isFirst) {
          this.vaccDetailListTab = newArr
        } else {
          this.vaccDetailListTab = this.saveList[this.prevNumber]
        }
      }
    },
    // 第一次赋值
    getFirstData() {
      for (const key in this.cageMap) {
        let newArr = []
        this.cageMap[key].forEach(item => {
          let newObj = {
            cageNo: item.cageNo,
            gender: item.gender,
            isFail: 0,
            overbit: item.overbit,
            partAniId: item.id,
            remark: "",
            susDetailIds: "",
            sites: []
          }
          newArr.push(newObj)
        })
        this.saveList[key] = newArr
      }
    },
    // 获取接种部位
    getSusList() {
      this.$get('/model/susDetail/list/' + this.id).then(res => {
        if (res.status == 200) {
          this.susList = res.data
        }
      })
    },
    clickTab(ev) {
      this.saveList[this.prevNumber] = this.vaccDetailListTab
      this.prevNumber = ev.name;
      this.firstIndex = ev.index
      this.getDataWay()
    },
    getAppId(id, name) {
      this.modelVacc.reviewer = id
      this.modelVacc.reviewName = name
    },
    getCheckedVacc(val, row) {
      row.susDetailIds = val
      row.susDetailIds = row.susDetailIds.toString()
    },
    saveRecord(state) {
      let endResult = [];
      let isAre;
      let message = [];
      for (const key in this.saveList) {
        this.saveList[key].forEach(item => {
          // 如果没切换tab
          if (this.firstIndex == null) {
            this.vaccDetailListTab.forEach(obj => {
              if (obj.partAniId == item.partAniId) {
                item = obj
              }
            })
          }
          if (item.susDetailIds == '') {
            isAre = !isAre && false
            message.push("接种部位不能为空")
          } else {
            endResult.push(item)
          }
        })
      }
      if (message && message.length > 0) {
        return this.$message({message: '所有笼舍接种部位不能为空', type: 'warning'})
      }
      this.modelVacc.vaccDetailList = endResult
      this.modelVacc.state = state
      this.modelVacc.subId = Number(this.id)


      if ($.trim(this.modelVacc.reviewer).length === 0) {
        this.$message.warning("请选择复核人")
        return false;
      }

      this.$postJson('/model/vacc', this.modelVacc).then(res => {
        if (res.status == 201) {
          this.$message.success("提交成功")
          this.$router.go(-1)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

</style>
